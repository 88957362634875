var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"tw-flex tw-w-full tw-h-auto tw-justify-center tw-items-center",staticStyle:{"background":"white"}},[_c('v-col',{staticClass:"tw-hidden lg:tw-flex left-side",style:({backgroundImage:("url(" + _vm.bg + ")")}),attrs:{"sm":"12","lg":"7"}},[_c('v-col',{staticClass:"left-side-body",attrs:{"sm":"12"}},[_c('img',{staticStyle:{"height":"80px","width":"286px","cursor":"pointer"},attrs:{"src":_vm.logo,"alt":"logo"},on:{"click":_vm.goHome}}),_c('div',{staticClass:"tw-flex tw-flex-row tw-flex-row tw-items-end socials"},[_c('p',{staticClass:"admin"},[_vm._v("Instagram")]),_c('p',{staticClass:"admin"},[_vm._v(" | ")]),_c('p',{staticClass:"admin"},[_vm._v("LinkedIn")]),_c('p',{staticClass:"admin"},[_vm._v(" | ")]),_c('p',{staticClass:"admin"},[_vm._v("Twitter")]),_c('p',{staticClass:"admin"},[_vm._v(" | ")]),_c('p',{staticClass:"admin"},[_vm._v("Facebook")])])])],1),_c('v-col',{staticClass:"right-side",attrs:{"sm":"12","lg":"5"}},[_c('v-col',{staticClass:"right-side-body",attrs:{"sm":"12"}},[_c('img',{staticClass:"tw-flex mb-3 mt-6 tw-cursor-pointer tw-justify-start tw-self-start",staticStyle:{"height":"34px","width":"auto"},attrs:{"src":_vm.logo,"alt":"logo"},on:{"click":_vm.goHome}}),_c('h6',{staticClass:"login-header mb-1 mb-md-5"},[_vm._v("Enter Your New Password ")]),_c('validation-observer',{ref:"observer",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('form',{on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.handleNewPassword)}}},[_c('validationProvider',{attrs:{"name":"Password","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var classes = ref.classes;
var errors = ref.errors;
return [_c('div',{staticClass:"tw-pt-4 tw-mb-4",class:classes},[_c('v-text-field',{attrs:{"hide-details":"","append-icon":_vm.value ? 'mdi-eye' : 'mdi-eye-off',"type":_vm.value ? 'text' : 'password',"solo":"","placeholder":"Password","name":"password","id":"password"},on:{"click:append":function () { return (_vm.value = !_vm.value); }},model:{value:(_vm.newPasswordData.newPassword),callback:function ($$v) {_vm.$set(_vm.newPasswordData, "newPassword", $$v)},expression:"newPasswordData.newPassword"}}),_c('span',[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)}),_c('validationProvider',{attrs:{"name":"Confirm Password","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var classes = ref.classes;
var errors = ref.errors;
return [_c('div',{staticClass:"tw-pt-4 tw-mb-4",class:classes},[_c('v-text-field',{attrs:{"hide-details":"","append-icon":_vm.confirmValue ? 'mdi-eye' : 'mdi-eye-off',"type":_vm.confirmValue ? 'text' : 'password',"solo":"","placeholder":"Confirm password","name":"password","id":"confirmPassword"},on:{"click:append":function () { return (_vm.confirmValue = !_vm.confirmValue); }},model:{value:(_vm.newPasswordData.confirmPassword),callback:function ($$v) {_vm.$set(_vm.newPasswordData, "confirmPassword", $$v)},expression:"newPasswordData.confirmPassword"}}),_c('span',[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)}),_c('base-button',{staticClass:"tw-mt-8",attrs:{"width":"100%","button-text":"Send","loading":_vm.loading,"type":"submit"}})],1)]}}])})],1)],1),_c('modal',{attrs:{"dialog":_vm.dialog,"title":"Thank you","additional-text":"Password reset successfully!","icon":_vm.check},on:{"close":_vm.toggleModal}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }