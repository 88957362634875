import router from "@/router";
import callEndpoint from "@/services/api/index";
import METHODS from "@/services/api/apiMethods";
import VueJwtDecode from "vue-jwt-decode";

const {
  // GET,
  POST,
  // PUT,
  // PATCH,
  // DELETE
} = METHODS;

let AUTH_URL;

if (process.env.NODE_ENV === "development") {
  AUTH_URL = process.env.VUE_APP_DEV_AUTH_SERVER_URL;
} else {
  AUTH_URL = process.env.VUE_APP_DEV_AUTH_SERVER_URL;
}

const authServiceCallEndpoint = ({ method, url, data, needsAuth }) => {
  return callEndpoint({ method, url, data, needsAuth }, AUTH_URL);
};

export const login = async  (user) => {
  const url = "/api/user/login";
  const { data } = await authServiceCallEndpoint({
    method: POST,
    data: user,
    url,
  });
  if (data.access_token)
  getUserData(data.access_token)
};

export const getUserData =(data) =>{

  let decodedToken = VueJwtDecode.decode(data);
  const base64Url = data.split(".")[1];
  const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
  const jsonPayload = decodeURIComponent(
    atob(base64)
      .split("")
      .map((c) => "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2))
      .join("")
  );
  sessionStorage.setItem("userData", JSON.stringify(decodedToken));
  sessionStorage.setItem("userToken", JSON.stringify(data));

  return jsonPayload;
}


export const logout = () => {
  sessionStorage.removeItem("userToken");
  sessionStorage.removeItem("userData");
  sessionStorage.removeItem("companyData");
  sessionStorage.clear()
  router.push({ name: 'HomePage' }).then(() =>{})
};


export const guardianSignUp = (data) => {
  const url = "/api/guardian/signup";
  return authServiceCallEndpoint({ method: POST, url, data });
};
export const verifyEmail = (data) => {
  const url = "/auth/accounts/verify-registration/";
  return authServiceCallEndpoint({ method: POST, url, data });
};
export const resetPassword = (data) => {
  const url = "/api/user/password-recovery";
  return authServiceCallEndpoint({ method: POST, url, data });
};
export const resetNewPassword = (data, query) => {
  const url = `/api/user/new-password/?code=${query}`;
  return authServiceCallEndpoint({ method: POST, url, data });
};